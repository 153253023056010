import { ClickAwayListener } from "@mui/base";
import { ExpandMore, Info } from "@mui/icons-material";
import {
  Fade,
  Popper,
  PopperPlacementType,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode, useState } from "react";

const HelpWidget = ({
  title,
  details,
  placement,
}: {
  title: string;
  details: string | ReactNode;
  placement: PopperPlacementType;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onClick">
      <Box>
        <Box
          onClick={handleClick}
          sx={{
            pl: "1rem",
            cursor: "pointer",
            borderRadius: "0.3rem",
            pr: "0.7rem",
            py: "0.7rem",
            background: theme.palette.transparent.blue,
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            color: theme.palette.primary.light,
          }}
        >
          <Info sx={{ fill: theme.palette.primary.light }} />
          {title}
          <ExpandMore sx={{ fill: theme.palette.primary.light }} />
        </Box>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          sx={{ position: "relative", zIndex: 9999, whiteSpace: "pre-line" }}
        >
          <Fade in={open}>
            <Box
              sx={{
                p: "1rem",
                background: theme.palette.common.white,
                boxShadow: theme.shadows[24],
                borderRadius: "0.3rem",
                maxWidth: "40rem",
              }}
            >
              {typeof details === "string" ? (
                <Typography>{details}</Typography>
              ) : (
                details
              )}
            </Box>
          </Fade>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default HelpWidget;
