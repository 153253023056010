import { Close } from "@mui/icons-material";
import { Box, Fade, Stack, Tooltip, Typography } from "@mui/material";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import { t } from "i18next";
import { SetStateAction } from "react";
import { useSelector } from "react-redux";
import {
  IDailyContentPlaylist,
  ITimeLineItem,
  Time,
} from "../../../../../types/NendaTypes";
import {
  dateToHourMinuteObject,
  hourMinuteObjectToDate,
} from "../../../../utils/timeUtil";
import { CustomerPortalState } from "../../store";
import { selectContentChannelById } from "../../store/reducers/contentChannelReducer";
import TextInput from "../../ui-components/input/TextInput";
import DisabledFeatures from "../Content/components/DisabledFeatures";

const ChannelRangeSelectItem = ({
  index,
  timelineItem,
  handleChange,
  isForbidden,
}: {
  index: number;
  timelineItem: ITimeLineItem;
  handleChange: (value: SetStateAction<IDailyContentPlaylist>) => void;
  isForbidden: boolean;
}) => {
  const contentChannel = useSelector((state: CustomerPortalState) =>
    selectContentChannelById(state, timelineItem.contentChannel)
  );
  const startTime = hourMinuteObjectToDate(timelineItem.interval.start);
  const endTime = hourMinuteObjectToDate(timelineItem.interval.end);

  const handleTimeChange = (property: string, value: Time) => {
    handleChange((prevState) => {
      const newTimeline = [...prevState.timeline];
      newTimeline[index] = {
        ...newTimeline[index],
        interval: {
          ...newTimeline[index].interval,
          [property]: value,
        },
      };
      return { ...prevState, timeline: newTimeline };
    });
  };

  const handleRemoveChannel = () => {
    handleChange((prevState) => {
      const newTimeline = [...prevState.timeline];
      newTimeline.splice(index, 1);
      return { ...prevState, timeline: newTimeline };
    });
  };

  return (
    <Fade
      in={!!timelineItem}
      key={timelineItem.contentChannel + index}
      unmountOnExit={true}
    >
      <Stack
        justifyContent={"space-between"}
        direction="column"
        alignItems="center"
        borderBottom="1px solid #e7e7e7"
      >
        <Stack direction="row" alignItems="center" gap="1rem">
          <Typography variant="body1" sx={{ fontSize: "0.7rem", pt: "1rem" }}>
            {contentChannel?.name}
          </Typography>
          {contentChannel && (
            <DisabledFeatures
              channel={contentChannel}
              sx={{
                display: "flex",
                alignItems: "center",
                zIndex: 1,
                backgroundColor: "white",
                p: "0.2rem",
              }}
            />
          )}
        </Stack>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <Stack
              direction={"row"}
              gap="1rem"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <TimePicker
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: null,
                }}
                slots={{
                  textField: TextInput,
                }}
                openTo={"hours"}
                onChange={(value) =>
                  handleTimeChange(
                    "start",
                    dateToHourMinuteObject(value || new Date())
                  )
                }
                value={startTime}
                maxTime={null}
                minutesStep={30}
                slotProps={{
                  popper: {
                    sx: (theme) => ({
                      "& .MuiClockNumber-root": {
                        color: theme.palette.primary.main,
                        "& .Mui-disabled": {
                          color: theme.palette.text.disabled,
                        },
                      },
                    }),
                  },
                  textField: {
                    InputProps: {
                      disableUnderline: isForbidden ? false : true,
                      sx: (theme) => ({
                        maxWidth: "12ch",
                        borderBottom: isForbidden
                          ? `1px solid ${theme.palette.error.light}`
                          : "none",
                      }),
                    },
                    variant: "standard",
                    placeholder: t("common.from") || "From",
                  },
                }}
              />
              <TimePicker
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: null,
                }}
                slots={{
                  textField: TextInput,
                }}
                onChange={(value) =>
                  handleTimeChange(
                    "end",
                    dateToHourMinuteObject(value || new Date())
                  )
                }
                value={endTime}
                minTime={null}
                minutesStep={30}
                openTo={"hours"}
                slotProps={{
                  popper: {
                    sx: (theme) => ({
                      "& .MuiClockNumber-root": {
                        color: theme.palette.primary.main,
                        "& .Mui-disabled": {
                          color: theme.palette.grey[300],
                        },
                      },
                    }),
                  },
                  textField: {
                    InputProps: {
                      disableUnderline: true,
                      sx: (theme) => ({
                        maxWidth: "12ch",
                        borderBottom: isForbidden
                          ? `1px solid ${theme.palette.error.light}`
                          : "none",
                      }),
                    },
                    variant: "standard",
                    placeholder: t("common.to") || "To",
                  },
                }}
              />
              <Tooltip
                title={t("customerportal.pages.daily_playlists.remove_channel")}
              >
                <Close
                  onClick={handleRemoveChannel}
                  sx={{ height: "0.9rem", cursor: "pointer" }}
                />
              </Tooltip>
            </Stack>
          </LocalizationProvider>
        </Box>
      </Stack>
    </Fade>
  );
};

export default ChannelRangeSelectItem;
