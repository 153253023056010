import { Box, Typography, useTheme } from "@mui/material";
import { IContentChannel } from "@nendaTypes/NendaTypes";

const ContentCard = ({
  contentChannel,
  onClick,
}: {
  contentChannel: IContentChannel;
  onClick: (contentChannelId: string) => void;
}) => {
  const theme = useTheme();
  const onCardClick = () => {
    onClick(contentChannel._id);
  };
  return (
    <Box
      sx={{
        padding: "0.5rem",
        border: "0.5px solid lightgray",
        cursor: "pointer",
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        background: "transparent",
        transition: "all 0.5s ease",

        "&:hover": {
          transition: "background 0.1s ease",
          background: "#dbd9d9",
          boxShadow: "6px 7px 31px -3px rgba(0,0,0,0.1)",
        },
        "&:active": {
          transition: "all 0.5s ease",
          background: theme.palette.transparent.purple,
        },
      }}
      onClick={onCardClick}
    >
      <Box
        sx={{
          height: "2rem",
          width: "3rem",
          background: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "0.2rem",
        }}
      >
        <img
          src={contentChannel.logo?.locator}
          style={{ height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box>
        <Typography variant="body1" sx={{ color: "#2c2c2c" }}>
          {contentChannel.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContentCard;
