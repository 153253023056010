import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { Box, Chip, Typography, useTheme } from "@mui/material";

import {
  IContentChannel,
  ITimeInterval,
  ITimeLineItem,
} from "@nendaTypes/NendaTypes";
import { useEffect, useMemo, useRef, useState } from "react";
import { findAvailableSlot } from "../../DailyPlaylist/helpers/findSlot";
import DroppableTimelineContainer from "./DroppableTimelineContainer";
import { adjustItemInterval } from "./helpers/timelineHelpers";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import ContentCard from "./ContentCard";
import { timeToMinutesByType } from "@client/utils/timeUtil";
import { t } from "i18next";

export interface TimelineItemWithId extends ITimeLineItem {
  id: number;
}
const contentTypes = ["Content Channels"]; // This is a placeholder for the actual content types that will come with digital signage playlists
const DragAndDropPlaylist = ({
  onTimelineChange,
  timeline,
  contentChannels,
  onAddContent,
}: {
  onTimelineChange: (timeline: ITimeLineItem[]) => void;
  timeline: ITimeLineItem[];
  contentChannels: IContentChannel[];
  onAddContent: (contentChannelId: string) => void;
}) => {
  const contentChannelCategories = contentChannels
    .map((cc) => cc.category)
    .reduce((acc: string[], curr) => {
      if (acc.includes(curr)) {
        return acc;
      }
      return [...acc, curr];
    }, []);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>(contentTypes[0]);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const contentChannelsFiltered =
    selectedCategory === "All"
      ? contentChannels
      : contentChannels.filter((ch) => ch.category === selectedCategory);

  // Update containerWidth after the component mounts
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  // Update containerWidth on window resize
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const firstFreeInterval = findAvailableSlot(
    timeline?.map((t) => t.interval),
    60,
    240
  );

  const timeLineItemsWithId = useMemo(() => {
    return timeline.map((item, i) => {
      return {
        ...item,
        id: i,
      };
    });
  }, [timeline]);

  const onDragEnd = (event: DragEndEvent) => {
    if (!event.active.data.current) return;

    if (event.active.data.current.type === "contentChannel-add") {
      const contentChannelId = event.active.data.current.contentChannel._id;
      if (!firstFreeInterval) return;

      const newItems = [
        ...timeline,
        {
          contentChannel: contentChannelId,
          interval: firstFreeInterval as ITimeInterval,
          id: timeline.length,
        },
      ];
      onTimelineChange(newItems);
    } else if (event.active.data.current.type === "timelineItem") {
      // Handle moving existing timeline items
      const draggedItem = event.active.data.current.item;

      // Calculate movement in pixels
      const deltaX = event.delta.x;

      // Convert deltaX to minutes
      const totalMinutesInDay = 24 * 60;
      const pixelsPerMinute = containerWidth / totalMinutesInDay;
      const minutesMoved = deltaX / pixelsPerMinute;

      // Snap minutesMoved to 30-minute increments
      const snappedMinutesMoved = Math.round(minutesMoved / 30) * 30;

      // Calculate new start and end times
      const startMinutes = timeToMinutesByType(
        draggedItem.interval.start,
        "start"
      );
      const endMinutes = timeToMinutesByType(draggedItem.interval.end, "end");

      const newStartMinutes = startMinutes + snappedMinutesMoved;
      const newEndMinutes = endMinutes + snappedMinutesMoved;

      // Use the shared adjustItemInterval function
      const updatedItem = adjustItemInterval(
        draggedItem,
        newStartMinutes,
        newEndMinutes,
        timeLineItemsWithId
      );

      // Update the timeline
      const updatedTimeline = timeLineItemsWithId.map((item) =>
        item.id === draggedItem.id ? updatedItem : item
      );
      onTimelineChange(updatedTimeline);
    }
  };
  const theme = useTheme();
  const showCategorySelect = contentTypes.length > 1;
  const dynamicContentChannels = contentChannelsFiltered || contentChannels;
  return (
    <Box>
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToParentElement]}>
        <Box>
          <Box
            sx={{
              p: "0.5rem",
              background: theme.palette.transparent.blue,
              mb: "1rem",
            }}
          >
            <DroppableTimelineContainer
              timeline={timeLineItemsWithId}
              contentChannels={contentChannels}
              onTimelineChange={onTimelineChange}
              containerWidth={containerWidth}
              containerRef={containerRef}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              mb: "1rem",
            }}
          >
            <Typography variant="body2">
              {t(
                "customerportal.pages.daily_playlists.content_title_descriptor"
              )}
            </Typography>
            <Box>
              {showCategorySelect && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    borderBottom: "0.5px solid lightgray",
                    pb: "1rem",
                    mb: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: "0.7rem" }}>
                    Content Type
                  </Typography>

                  {contentTypes.map((type) => (
                    <Chip
                      label={type}
                      key={type}
                      sx={{
                        border: "0.5px solid lightgray",
                        px: "1rem",
                        py: "0.5rem",
                        cursor: "pointer",
                        background:
                          type === selectedType
                            ? theme.palette.transparent.blue
                            : "white",
                      }}
                      onClick={() => setSelectedType(type)}
                    />
                  ))}
                </Box>
              )}

              {contentChannelCategories.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: "0.7rem" }}>
                    Categories
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "0.5rem",
                    }}
                  >
                    {["All", ...contentChannelCategories].map((category) => (
                      <Chip
                        label={category}
                        key={category}
                        sx={{
                          border: "0.5px solid lightgray",
                          px: "1rem",
                          py: "0.5rem",
                          cursor: "pointer",
                          background:
                            selectedCategory === category
                              ? theme.palette.transparent.blue
                              : "white",
                        }}
                        onClick={() => setSelectedCategory(category)}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              flexWrap: "wrap",
              gap: "0.5rem",
              minHeight: "15rem",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            {dynamicContentChannels.map((cc) => (
              <ContentCard
                key={cc._id}
                contentChannel={cc}
                onClick={onAddContent}
              />
            ))}
          </Box>
        </Box>
      </DndContext>
    </Box>
  );
};

export default DragAndDropPlaylist;
