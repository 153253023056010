import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { Resizable } from "react-resizable";
import { useDraggable } from "@dnd-kit/core";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { CSS } from "@dnd-kit/utilities";
import {
  adjustItemInterval,
  calculateLeftPosition,
  calculateWidth,
  percentWidthToPixels,
  pixelsPerMinutes,
} from "./helpers/timelineHelpers";
import { getPrettyTimeRange } from "@client/utils/timeline";
import {
  IContentChannel,
  ITimeInterval,
  ITimeLineItem,
} from "@nendaTypes/NendaTypes";
import { TimelineItemWithId } from "./DragAndDropPlaylist";
import { timeToMinutesByType } from "@client/utils/timeUtil";
import { mergeRefs } from "@client/utils/refs";

const DraggableTimelineItem = React.memo(function DraggableTimelineItem({
  item,
  allItems,
  containerWidth,
  contentChannels,
  onItemChange,
}: {
  allItems: TimelineItemWithId[];
  item: TimelineItemWithId;
  containerWidth: number;
  contentChannels: IContentChannel[];
  onItemChange: (items: ITimeLineItem[]) => void;
}) {
  const timeInterval = item.interval;

  const handleTimelineChange = (id: number, interval: ITimeInterval) => {
    const newItems = allItems.map((itm) =>
      itm.id === id ? { ...itm, interval } : itm
    );
    onItemChange(newItems);
  };

  const contentChannel = contentChannels.find(
    (cc) => cc._id === item.contentChannel
  );

  const [workingWidth, setWorkingWidth] = useState<number>(0);
  const [leftPosition, setLeftPosition] = useState<number>(0);
  const [resizingHandle, setResizingHandle] = useState<string | null>(null);

  // Recalculate workingWidth and leftPosition when containerWidth or timeInterval changes
  useLayoutEffect(() => {
    const widthPercent = calculateWidth(timeInterval.start, timeInterval.end);
    const itemWidthInPixels = percentWidthToPixels(
      widthPercent,
      containerWidth
    );
    setWorkingWidth(itemWidthInPixels);

    const newLeftPositionPercent = calculateLeftPosition(timeInterval.start);
    setLeftPosition(newLeftPositionPercent);
  }, [containerWidth, timeInterval]);
  const gridSnapPixels = pixelsPerMinutes(30, containerWidth);

  const { setNodeRef, attributes, listeners, transform } = useDraggable({
    id: item.id.toString(),
    data: {
      type: "timelineItem",
      item, // Provide the entire item data
    },
  });

  const handleRemoveItem = () => {
    const newItems = allItems.filter((i) => i.id !== item.id);
    onItemChange(newItems);
  };

  const pixelsPerMinute = containerWidth / (24 * 60);
  const pixelsPer30Minutes = pixelsPerMinutes(30, containerWidth);

  const minDuration = 30; // Define minDuration here
  const timelineStartMinutes = 0;
  const timelineEndMinutes = 1440;

  const onResize = (event, { size, handle }) => {
    setResizingHandle(handle);
    const snappedWidth =
      Math.round(size.width / gridSnapPixels) * gridSnapPixels;

    // Update workingWidth directly for smooth resizing
    setWorkingWidth(snappedWidth);

    if (handle === "w") {
      // Calculate the change in width
      const widthChange = workingWidth - snappedWidth;

      // Calculate the percentage change in width
      const widthChangePercent = (widthChange / containerWidth) * 100;

      // Update left position
      setLeftPosition(
        (prevLeftPosition) => prevLeftPosition + widthChangePercent
      );
    }
  };

  const onResizeStop = (event, { size, handle }) => {
    const snappedWidth =
      Math.round(size.width / gridSnapPixels) * gridSnapPixels;
    const startMinutes = timeToMinutesByType(timeInterval.start, "start");
    const endMinutes = timeToMinutesByType(timeInterval.end, "end");

    let newStartMinutes = startMinutes;
    let newEndMinutes = endMinutes;

    const durationMinutes = snappedWidth / pixelsPerMinute;

    if (handle === "e") {
      // Resizing to the east/right
      newEndMinutes = Math.min(
        newStartMinutes + durationMinutes,
        timelineEndMinutes
      );
    } else if (handle === "w") {
      // Resizing to the west/left
      newStartMinutes = Math.max(
        newEndMinutes - durationMinutes,
        timelineStartMinutes
      );
    }

    // Ensure duration is at least minDuration
    if (newEndMinutes - newStartMinutes < minDuration) {
      if (handle === "e") {
        newEndMinutes = Math.min(
          newStartMinutes + minDuration,
          timelineEndMinutes
        );
      } else if (handle === "w") {
        newStartMinutes = Math.max(
          newEndMinutes - minDuration,
          timelineStartMinutes
        );
      }
    }

    // Use the shared adjustItemInterval function
    const updatedItem = adjustItemInterval(
      item,
      newStartMinutes,
      newEndMinutes,
      allItems,
      minDuration
    );

    // Update workingWidth and timeInterval
    const updatedDurationMinutes =
      timeToMinutesByType(updatedItem.interval.end, "end") -
      timeToMinutesByType(updatedItem.interval.start, "start");

    const newWidth = updatedDurationMinutes * pixelsPerMinute;

    setWorkingWidth(newWidth);

    // Update left position based on new start time
    const newLeftPositionPercent = calculateLeftPosition(
      updatedItem.interval.start
    );
    setLeftPosition(newLeftPositionPercent);

    handleTimelineChange(item.id, updatedItem.interval);

    setResizingHandle(null);
  };

  const itemRef = useRef<HTMLDivElement>(null);

  const displayTime = getPrettyTimeRange(timeInterval);
  const tightMode = workingWidth < pixelsPer30Minutes * 2;
  const theme = useTheme();

  // Calculate min and max constraints
  const minWidth = gridSnapPixels; // Minimum width corresponds to minDuration
  let maxWidth;

  if (resizingHandle === "e") {
    maxWidth = containerWidth - (leftPosition / 100) * containerWidth;
  } else if (resizingHandle === "w") {
    maxWidth = (leftPosition / 100) * containerWidth + workingWidth;
  } else {
    maxWidth = containerWidth;
  }

  return (
    <Resizable
      width={workingWidth}
      minConstraints={[minWidth, 0]}
      maxConstraints={[maxWidth, 0]}
      height={100}
      onResize={onResize}
      onResizeStop={onResizeStop}
      resizeHandles={["e", "w"]}
      handle={
        <CustomResizeHandle
          handleAxis="e"
          tooltipTitle={`${displayTime} ${contentChannel?.name}`}
        />
      }
      draggableOpts={{ grid: [gridSnapPixels, 0] }}
    >
      <Box
        ref={mergeRefs(setNodeRef, itemRef)}
        id="timeline-item"
        {...attributes}
        sx={{
          padding: tightMode ? "0rem" : "1rem",
          background: theme.palette.primary.light,
          color: "white",
          width: `${workingWidth}px`,
          height: "100%",
          outline: "1px solid white",
          display: "inline-block",
          position: "absolute",
          transform: CSS.Translate.toString(transform),
          left: `${leftPosition}%`,
          overflow: "hidden",
          borderRadius: "0.3rem",
        }}
      >
        <Box
          {...listeners}
          ref={itemRef}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "relative",
          }}
        >
          <Tooltip
            title={`${displayTime} ${contentChannel?.name}`}
            placement="top"
            enterDelay={2000}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!tightMode && (
                <Box sx={{ pb: "0.5rem" }}>
                  <Typography
                    variant="caption"
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "0.5rem",
                    }}
                  >
                    {"Content Channel"}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
                    {contentChannel?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "0.7rem" }}>
                    {displayTime}
                  </Typography>
                </Box>
              )}
            </Box>
          </Tooltip>
        </Box>
        <CloseOutlined
          onClick={handleRemoveItem}
          sx={{
            top: "0.3rem",
            right: tightMode ? 0 : "0.3rem",
            transform: tightMode ? "translateX(10%)" : "none",
            position: "absolute",
            height: "1rem",
          }}
        />
      </Box>
    </Resizable>
  );
});

interface CustomResizeHandleProps {
  handleAxis: string;
  tooltipTitle: string;
  [key: string]: any;
}

const CustomResizeHandle = forwardRef<HTMLDivElement, CustomResizeHandleProps>(
  ({ handleAxis, tooltipTitle, ...restProps }, ref) => {
    return (
      <Tooltip title={tooltipTitle} placement="top" enterDelay={2000}>
        <Box
          ref={ref}
          {...restProps}
          sx={{
            position: "absolute",
            right: handleAxis === "e" ? "-0.5rem" : "auto",
            left: handleAxis === "w" ? "-0.5rem" : "auto",
            borderRight: handleAxis === "w" ? "1px solid #cacaca" : "none",
            borderLeft: handleAxis === "e" ? "1px solid #cacaca" : "none",
            top: 20,
            bottom: 20,
            width: "0.5rem",
            cursor: "ew-resize",
            padding: "6px",
            "&:hover": {
              background: "rgba(0,0,0,0.2)",
            },
          }}
        ></Box>
      </Tooltip>
    );
  }
);

CustomResizeHandle.displayName = "CustomResizeHandle";
DraggableTimelineItem.displayName = "DraggableTimelineItem";

export default DraggableTimelineItem;
