import { Add } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { IContentChannel } from "../../../../../types/NendaTypes";
import posterPlaceholderImage from "../../assets/images/poster-placeholder.png";
import {
  selectCategorizedContentChannelsByNavigatedPremise,
  selectContentChannelsByNavigatedPremise,
} from "../../store/reducers/contentChannelReducer";
import DisabledFeatures from "../Content/components/DisabledFeatures";

const SmallContentChannelCarousel = ({
  handleAddChannel,
  channelSelectTitle,
}: {
  handleAddChannel: (value: string) => void;
  channelSelectTitle: string;
}) => {
  const categorizedContentChannels = useSelector(
    selectCategorizedContentChannelsByNavigatedPremise
  );
  const allContentChannels = useSelector(
    selectContentChannelsByNavigatedPremise
  );
  const categorizedContentChannelsWithAll = {
    ...categorizedContentChannels,
    All: allContentChannels,
  };
  const [selectedCategory, setSelectedCategory] = useState<string>(
    Object.keys(categorizedContentChannelsWithAll)[0]
  );
  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <Box>
      <Stack direction="row" gap="0.5rem" px="1rem" pb="0.5rem">
        {Object.keys(categorizedContentChannelsWithAll).map((category) => {
          return (
            <Box
              key={category}
              onClick={() => handleSelectCategory(category)}
              sx={(theme) => ({
                padding: "0.5rem 1rem",
                borderRadius: "0.2rem",
                cursor: "pointer",
                background:
                  selectedCategory === category
                    ? theme.palette.transparent.purple
                    : "transparent",
              })}
            >
              <Typography variant="body2">{category}</Typography>
            </Box>
          );
        })}
      </Stack>
      <Stack direction={"row"} gap="0.5rem" px="1rem">
        <ChannelCarousel
          channels={categorizedContentChannelsWithAll[selectedCategory]}
          handleAddChannel={handleAddChannel}
          channelSelectTitle={channelSelectTitle}
        />
      </Stack>
    </Box>
  );
};
const ChannelCarouselItem = ({
  channel,
  onAddChannel,
  channelSelectTitle,
}: {
  channel: IContentChannel;
  onAddChannel: (value: string) => void;
  channelSelectTitle: string;
}) => {
  const handleAddChannel = () => {
    onAddChannel(channel._id);
  };

  return (
    <Box
      sx={{
        height: "12rem",
        backgroundColor: "white",
        mr: "0.5rem",
        backgroundImage: `url(${channel?.posterUrl || posterPlaceholderImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        position: "relative",
        "&:hover": {
          "& .overlay-add": {
            visibility: "visible",
          },
        },
        "& .overlay-add": {
          cursor: "pointer",
          visibility: "hidden",
          backgroundColor: "rgba(0,0,0,0.9)",
        },
      }}
    >
      <DisabledFeatures
        channel={channel}
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          zIndex: 1,
          top: 0,
          right: 0,
          backgroundColor: "white",
          p: "0.2rem",
        }}
      />
      <Box
        className="overlay-add"
        onClick={handleAddChannel}
        sx={{
          position: "absolute",
          inset: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Add sx={{ fill: "white" }} />
          <Typography variant="body2" color="white">
            {channelSelectTitle ||
              t("customerportal.pages.daily_playlists.add_channel")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: "rgba(0,0,0,0.7)",
          height: "4rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          px: "0.5rem",
        }}
      >
        <Typography variant="body1" textAlign={"center"}>
          {channel.name}
        </Typography>
      </Box>
    </Box>
  );
};

const ChannelCarousel = ({
  channels,
  handleAddChannel,
  channelSelectTitle,
}: {
  channels: IContentChannel[];
  handleAddChannel: (value: string) => void;
  channelSelectTitle: string;
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 4, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Carousel partialVisible={true} responsive={responsive}>
        {channels?.map((channel) => (
          <ChannelCarouselItem
            key={channel._id}
            channel={channel}
            onAddChannel={handleAddChannel}
            channelSelectTitle={channelSelectTitle}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default SmallContentChannelCarousel;
